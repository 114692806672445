import { useRecordContext } from "react-admin";

export const FieldList = ({ field }) => {
  const record = useRecordContext();

  return (
    <>
      {record[field]?.map((value, i) => (
        <div key={`${record.id}_${value}_${i}`}>{value}</div>
      ))}
    </>
  );
};
