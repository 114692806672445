import * as React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Labeled,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@mui/styles";

import { TextFileDisplay } from "../../common/TextFileDisplay";
import { fetchSignedUrl } from "../../../utils/Nrj";

const useStyles = makeStyles({
  fullWidth: { width: "100%" },
  displayField: {
    width: "100%",
    height: "500px",
  },
});

const getLogs = async (fileId, setRawLog) => {
  try {
    if (!fileId) {
      setRawLog({ info: "No log file to be displayed" });
      return;
    }
    const url = await fetchSignedUrl(fileId);
    setRawLog({ rawLog: url });
  } catch (e) {
    setRawLog({ info: e.toString() || "error retrieve" });
  }
};

export const LogsRaw = (props) => {
  const { source, label } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();
  const classes = useStyles();
  const [rawLog, setRawLog] = React.useState({
    rawLog: null,
    info: translate("messages.loading"),
  });

  React.useEffect(() => {
    getLogs(record[source], setRawLog);
  }, [record, source]);

  // If info to display instead of rawLog, display it
  if (rawLog.info) {
    return (
      <Labeled label={label} className={classes.fullWidth}>
        <TextField record={rawLog} source="info" />
      </Labeled>
    );
  }

  return (
    <TextFileDisplay
      record={rawLog}
      source="rawLog"
      fileType="text/plain"
      label={label}
      errorMessage={translate("internalImportsTab.journal.notAvailable")}
    />
  );
};

LogsRaw.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
