import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  EditButton,
  Edit,
  required,
} from "react-admin";

const connectorFilters = [
  <TextInput source="name" alwaysOn={true} resettable={true} />,
];

export const ConnectorList = (props) => (
  <List filters={connectorFilters} {...props}>
    <Datagrid rowClick="toggleSelection">
      <TextField source="name" />
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ConnectorEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const ConnectorCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);
