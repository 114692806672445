const Resource = {
  // BSM ressources
  connectors: "connectors",
  providers: "providers",
  customerAreas: "customer-areas",
  synchronizations: "synchronizations",
  synchronizationFiles: "sync-files",

  //NRJ ressources
  companies: "companies",
  groups: "groups",
  internalImports: "internal-imports",
  fileStorages: "file-storages",

  //IOT ressources
  sources: "dataSource",
  externalContractors: "externalContractors",
  customers: "customers",

  //FeatureFlago ressources
  flags: "flags",
  applications: "applications",
};

export { Resource };
