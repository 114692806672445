import * as React from "react";
import { Link } from "@mui/material";
import { useRecordContext } from "react-admin";

/**
 * NRJ pages urls config
 */
const nrjPages = {
  collect: "#collecte",
};

/**
 * Returns the url of given NRJ page. If it's a resource, adds the id to the url.
 * @param {keyof nrjPages} page
 * @param {string} id optionnal
 */
function getNrjUrl(page, id = null) {
  const pageUrl = `${process.env.REACT_APP_NRJ_WEBAPP}/${nrjPages[page]}`;

  return id ? pageUrl + `/${id}` : pageUrl;
}

/**
 * Returns a link component opening a new page from NRJ web app.
 * @param {object} params
 * @param {keyof nrjPages} params.page Page to open
 * @param {string} params.pathId Path of the record's property holding id value
 * @param {string} params.pathLabel Path of the record's property holding label value
 * @returns
 */
export const NrjPageLink = ({ page, pathId, pathLabel }) => {
  const record = useRecordContext();

  const id = record && record[pathId];
  const label = record && record[pathLabel];

  return (
    <Link href={getNrjUrl(page, id)} target="_blank">
      {label}
    </Link>
  );
};
