import * as React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
} from "react-admin";

import { FlagFilter } from "../../components/FeatureFlago/FeatureFlag";
import { Resource as ResourceName } from "../../utils/enums/resource.enum";

export const FeatureFlagsList = (props) => (
  <List filters={<FlagFilter />} {...props}>
    <Datagrid rowClick="toggleSelection">
      <NumberField source="id" disabled />
      <TextField source="label" />
      <TextField source="slug" />
      <BooleanField source="isEnabled" />
      <TextField source="application" />
      <TextField source="description" />
      <TextField source="owners" />
      <TextField source="projects" />
      <TextField source="createdBy" />
      <DateField showTime={true} source="createdAt" />
      <TextField source="updatedBy" />
      <DateField showTime={true} source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const FeatureFlagsEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <NumberField source="id" disabled />
      <TextInput source="label" optionValue="label" />
      <BooleanInput
        source="isEnabled"
        defaultValue={true}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export const FeatureFlagsCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="label" validate={required()} optionText="label" />
      <ReferenceInput
        source="application"
        reference={ResourceName.applications}
        resettable={true}
        sort={{ field: "label", order: "ASC" }}
        validate={required()}
      >
        <AutocompleteInput
          optionText="label"
          optionValue="label"
          filterToQuery={(searchText) =>
            searchText ? { name: searchText.label } : {}
          }
          debounce={500}
        />
      </ReferenceInput>
      <BooleanInput
        source="isEnabled"
        defaultValue={true}
        validate={required()}
      />
      <TextInput source="description" />

      <ArrayInput source="projects">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="owners" validate={required()}>
        <SimpleFormIterator>
          <TextInput validate={required()} defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
