import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FormDataConsumer,
  List,
  ReferenceField,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

import {
  ActionButton,
  CustomerAreasInput,
  GroupInput,
  SynchronisationPanel,
  SyncListFilters,
} from "../../components/BillSyncManager/Synchronizations";
import { ActionMenu } from "../../components/common";
import { NrjPageLink } from "../../components/Nrj";
import { Resource } from "../../utils/enums";

export const SynchronizationList = (props) => {
  return (
    <List
      filters={SyncListFilters()}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
    >
      <Datagrid
        rowClick="expand"
        expand={<SynchronisationPanel />}
        bulkActionButtons={false}
      >
        <ReferenceField
          source="customerAreaId"
          reference={Resource.customerAreas}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="groupId"
          reference={Resource.groups}
          link={false}
        >
          <NrjPageLink page="collect" pathId="_id" pathLabel="name" />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <DateField source="createdAt" showTime={true} />
        <ChipField source="state" />
        <ActionMenu>
          <ActionButton action="launch" key="launch" />
          <ActionButton action="import" key="import" />
          <ActionButton action="edit" key="edit" />
          <ActionButton action="delete" key="delete" />
        </ActionMenu>
      </Datagrid>
    </List>
  );
};

export const SynchronizationCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <CustomerAreasInput />
      <FormDataConsumer>
        {(props) => <GroupInput {...props} />}
      </FormDataConsumer>
      <DateInput source="startDate" validate={required()} />
      <DateInput source="endDate" validate={required()} />
    </SimpleForm>
  </Create>
);

export const SynchronizationEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="list">
    <SimpleForm>
      <TextInput disabled source="id" />
      <CustomerAreasInput />
      <FormDataConsumer>
        {(props) => <GroupInput {...props} />}
      </FormDataConsumer>
      <DateInput source="startDate" validate={required()} />
      <DateInput source="endDate" validate={required()} />
    </SimpleForm>
  </Edit>
);
