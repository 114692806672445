import { Cancel, CropFree, Description } from "@mui/icons-material";
import PropTypes from "prop-types";
import { ImageField, Labeled } from "react-admin";
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useTranslate,
} from "ra-core";
import { Fragment, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

import { jsonHttpClient } from "../../services/dataProvider";

const useStyles = makeStyles(() => ({
  previewIcon: { fontSize: "100px" },
  cancelIcon: {
    position: "absolute",
    right: 0,
    left: 0,
    top: "1em",
    cursor: "pointer",
  },
}));

const ImagePreview = (props) => {
  const { record, urlField } = props;

  return <ImageField record={record} source={urlField} />;
};

const DocumentPreview = (props) => {
  const classes = useStyles();

  return <Description className={classes.icon} />;
};

const EmptyPreview = (props) => {
  const classes = useStyles();

  return <CropFree className={classes.icon} />;
};

export const FileInput = (props) => {
  const {
    label,
    source,
    type = "document",
    urlField = "url",
    nameField = "name",
  } = props;
  const classes = useStyles();
  const record = useRecordContext();
  const resource = useResourceContext();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const hiddenFileInput = useRef(null);
  const sourceValue = record[source];
  const nameValue =
    (sourceValue && sourceValue[nameField]) ||
    translate("components.fileInput.noFile");

  const handlePreviewClick = () => {
    hiddenFileInput.current.click();
  };
  const handleFileUpload = (event) => {
    if (event.target.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.set("_file", event.target.files[0]);
    const options = { method: "PUT", body: formData };
    callLogoEndpoint(options);
  };

  const handleFileDelete = () => {
    const options = { method: "DELETE" };
    callLogoEndpoint(options);
  };

  const callLogoEndpoint = (options) => {
    const url = `${process.env.REACT_APP_BSM_ENDPOINT}/${resource}/${record.id}/${source}`;
    jsonHttpClient(url, options)
      .then(() => {
        notify(translate("messages.fileUploadSuccess"), "success");
        refresh();
      })
      .catch(() => notify(translate("messages.fileUploadError"), "error"));
  };

  const previews = {
    document: (
      <DocumentPreview record={sourceValue} {...{ nameField, urlField }} />
    ),
    image: <ImagePreview record={sourceValue} {...{ urlField, nameField }} />,
    empty: <EmptyPreview />,
  };

  return (
    <Labeled label={label || source}>
      <Fragment>
        <Button onClick={handlePreviewClick}>
          {sourceValue ? previews[type] || previews.document : previews.empty}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileUpload}
            hidden
          />
        </Button>
        <br />
        <Typography component="span" variant="body2">
          {nameValue}
        </Typography>
        {sourceValue ? (
          <Cancel
            className={classes.cancelIcon}
            onClick={handleFileDelete}
            color="error"
          />
        ) : null}
      </Fragment>
    </Labeled>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  urlField: PropTypes.string,
  nameField: PropTypes.string,
};
