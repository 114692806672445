import { Fragment } from "react";
import { useTranslate, SimpleShowLayout } from "react-admin";

import { LogsRaw } from "./LogsRaw";
import { LogsSummary } from "./LogsSummary";

export const JournalTab = (props) => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <Fragment>
        <LogsSummary
          source="logSummaryFile"
          label={translate("internalImportsTab.journal.fields.summaryLogs")}
        />
        <LogsRaw
          source="logRawFile"
          fileType="text/plain"
          label={translate("internalImportsTab.journal.fields.rawLogs")}
        />
      </Fragment>
    </SimpleShowLayout>
  );
};
