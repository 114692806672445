import * as React from "react";
import PropTypes from "prop-types";
import { Labeled, useRecordContext } from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fullWidth: { width: "100%" },
  displayField: {
    width: "100%",
    height: "500px",
  },
});

export const TextFileDisplay = (props) => {
  const { source, fileType, label, errorMessage } = props;
  const record = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.fullWidth}>
      <Labeled label={label} className={classes.fullWidth}>
        <object
          id="displayField"
          data={record[source]}
          type={fileType}
          className={classes.displayField}
        >
          <p>{errorMessage}</p>
        </object>
      </Labeled>
    </div>
  );
};
TextFileDisplay.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
