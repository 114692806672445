import { Fragment } from "react";
import { useTranslate } from "react-admin";

import { TextFileDisplay } from "../../common";
import { LogsSummary } from "./LogsSummary";

export const LogsPanel = () => {
  const translate = useTranslate();

  return (
    <Fragment>
      <LogsSummary
        source="summaryLogsUrl"
        label={translate("syncTabs.journal.fields.summaryLogs")}
      />
      <TextFileDisplay
        source="rawLogsUrl"
        fileType="text/plain"
        label={translate("syncTabs.journal.fields.rawLogs")}
        errorMessage={translate("messages.logsJournalNotAvailable")}
      />
    </Fragment>
  );
};
