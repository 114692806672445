import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify,
  useTranslate,
} from "react-admin";

import { jsonHttpClient } from "../../../services/dataProvider";
import { Resource } from "../../../utils/enums";

export const SyncListFilters = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [stateChoices, setStateChoices] = useState([]);
  const [typeChoices, setTypeChoices] = useState([]);

  /** Sets the state filter with a list of job states */
  function setStateFilter(jobStates) {
    setStateChoices(
      jobStates.map((jobState) => ({
        id: jobState,
        name: jobState,
      }))
    );
  }

  /** Sets the type filter with a list of job types */
  function setTypeFilter(jobTypes) {
    setTypeChoices(
      jobTypes.map((jobType) => ({
        id: jobType,
        name: jobType,
      }))
    );
  }

  /** Fetch all available job states from the BSM */
  function fetchJobStates() {
    const url = `${process.env.REACT_APP_BSM_ENDPOINT}/${Resource.synchronizations}/states`;
    const options = { method: "GET" };

    return jsonHttpClient(url, options);
  }

  /** Fetch all available job types from the BSM */
  function fetchJobTypes() {
    const url = `${process.env.REACT_APP_BSM_ENDPOINT}/${Resource.synchronizations}/types`;
    const options = { method: "GET" };

    return jsonHttpClient(url, options);
  }

  useEffect(() => {
    fetchJobStates()
      .then((response) => setStateFilter(response.json))
      .catch(() => notify(translate("messages.fetchJobStatesError"), "error"));
    fetchJobTypes()
      .then((response) => setTypeFilter(response.json))
      .catch(() => notify(translate("messages.fetchJobTypesError"), "error"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // To trigger only once

  return [
    <ReferenceInput
      source="customerAreaId"
      reference={Resource.customerAreas}
      alwaysOn={true}
      allowEmpty={false}
      resettable={true}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        filterToQuery={(searchText) => (searchText ? { name: searchText } : {})}
        debounce={500}
        style={{ width: "500px" }}
      />
    </ReferenceInput>,
    <ReferenceInput
      source="clientId"
      reference={Resource.companies}
      alwaysOn={true}
      allowEmpty={false}
      resettable={true}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        filterToQuery={(searchText) => (searchText ? { name: searchText } : {})}
        debounce={500}
      />
    </ReferenceInput>,
    <TextInput source="groupId" alwaysOn={true} resettable={true} />,
    <SelectInput
      source="state"
      alwaysOn={true}
      allowEmpty={false}
      resettable={true}
      choices={stateChoices}
    />,
    <TextInput
      source="launchedBy"
      label={translate("resources.synchronizations.jobs.launchedBy")}
      resettable={true}
    />,
    <TextInput source="uuid" resettable={true} />,
    <SelectInput
      source="type"
      allowEmpty={false}
      resettable={true}
      choices={typeChoices}
    />,
  ];
};
