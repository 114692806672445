import * as React from "react";
import PropTypes from "prop-types";
import { Labeled, useRecordContext, useTranslate } from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fullWidth: { width: "100%" },
  displayField: {
    width: "100%",
    height: "500px",
  },
});

const getLogs = async (url, setLogs) => {
  try {
    const res = await fetch(url);
    const response = await res.json();
    setLogs(response);
  } catch (e) {
    setLogs({ status: "error_retrieve" });
  }
};

export const LogsSummary = (props) => {
  const { source, label } = props;
  const record = useRecordContext(props);
  const classes = useStyles();
  const translate = useTranslate();
  const [logs, setLogs] = React.useState({
    status: null,
    collectedInvoicesCount: null,
    skippedInvoicesCount: null,
  });

  React.useEffect(() => {
    getLogs(record[source], setLogs);
  }, [record, source]);

  return (
    <div className={classes.fullWidth}>
      <Labeled label={label} className={classes.fullWidth}>
        <table>
          <tbody>
            <tr>
              <th>
                {translate("resources.synchronizations.jobs.logs.status")}
              </th>
              <td>{logs.status}</td>
            </tr>
            <tr>
              <th>
                {translate(
                  "resources.synchronizations.jobs.logs.collectedInvoices"
                )}
              </th>
              <td>{logs.collectedInvoicesCount}</td>
            </tr>
            <tr>
              <th>
                {translate(
                  "resources.synchronizations.jobs.logs.skippedInvoices"
                )}
              </th>
              <td>{logs.skippedInvoicesCount}</td>
            </tr>
          </tbody>
        </table>
      </Labeled>
    </div>
  );
};

LogsSummary.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
