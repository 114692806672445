import { useState } from "react";
import {
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FileField,
  FileInput,
  List,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  TopToolbar,
  required,
  useNotify,
  useRedirect,
  useResourceContext,
  useTranslate,
} from "react-admin";

import {
  InternalImportPanel,
  InternalImportsListFilters,
  OptionFields,
} from "../../components/Nrj/InternalImports";
import { jsonHttpClient } from "../../services/dataProvider";

/**
 * All valid import types and their config available.
 * A config is loaded on the page it's corresponding type is selected.
 * Keys must match configuration in back api.
 * Order is the order of display (usually order of process when using all imports types).
 */
const IMPORTS_OPTIONS_CONFIGS = {
  sites_creation: [],
  legacy_data: [
    {
      source: "optionClearData",
      componentName: "BooleanInput",
      labelKey: "clear_data",
      defaultValue: false,
    },
  ],
  rrefs_assignment: [],
};
const IMPORT_TYPES_CHOICES = Object.keys(IMPORTS_OPTIONS_CONFIGS).map((f) => ({
  id: f,
  name: `resources.internal-imports.importTypes.${f}`,
}));

const ListActions = (props) => {
  const translate = useTranslate();

  return (
    <TopToolbar>
      <CreateButton label={translate("actions.import")} />
      <ExportButton />
    </TopToolbar>
  );
};

export const InternalImportsList = (props) => {
  return (
    <List
      filters={InternalImportsListFilters()}
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={false}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick="expand" expand={<InternalImportPanel />}>
        <TextField source="filename" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="executionEndedAt" showTime={true} />
        <ChipField source="state" />
      </Datagrid>
    </List>
  );
};

export const InternalImportCreate = (props) => {
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const [optionFields, setOptionFields] = useState([]);
  const [importType, setImportType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /** Change the form config based on import type selected */
  const handleTypeChange = (type) => {
    setImportType(type);
    setOptionFields(IMPORTS_OPTIONS_CONFIGS[type]);
  };
  /** Construct the request body and send the request */
  const handleFormSend = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "_file") {
        formData.set(key, value.rawFile);
        return;
      }
      formData.set(key, value);
    });
    const options = { method: "PUT", body: formData };
    callInternalImportEndpoint(options);
  };
  /** Send the request to the back to import file */
  const callInternalImportEndpoint = (options) => {
    const url = `${process.env.REACT_APP_NRJ_ENDPOINT}/${resource}`;
    // Ask confirmation when refresh or close page
    window.onbeforeunload = () => true;
    setIsLoading(true);
    jsonHttpClient(url, options)
      .then(() => {
        notify(translate("messages.fileUploadSuccess"), "success");
        setIsLoading(false);
        redirect(`/${resource}`);
      })
      .catch((err) => {
        notify(err.body || translate("messages.fileUploadError"), "error");
        setIsLoading(false);
      })
      .finally(() => {
        window.onbeforeunload = null;
      });
  };
  const PostCreateImportToolbar = () => (
    <Toolbar>
      <SaveButton disabled={isLoading} />
    </Toolbar>
  );
  return (
    <Create title={translate("actions.import")} {...props}>
      <SimpleForm
        onSubmit={handleFormSend}
        toolbar={<PostCreateImportToolbar />}
      >
        <SelectInput
          source="importType"
          label={translate("resources.internal-imports.fields.importType")}
          validate={required()}
          choices={IMPORT_TYPES_CHOICES}
          onChange={(event) => handleTypeChange(event.target.value)}
          defaultValue={importType}
        />
        <FileInput
          maxSize="20000000"
          source="_file"
          label={translate("resources.internal-imports.fields.importFile")}
          accept="text/csv"
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <OptionFields importType={importType} optionFields={optionFields} />
      </SimpleForm>
    </Create>
  );
};
