import { Resource } from "../enums";
import { jsonHttpClient } from "../../services/dataProvider";

/** Fetch signed URL from file strorage id */
export async function fetchSignedUrl(fileId) {
  const url = `${process.env.REACT_APP_NRJ_ENDPOINT}/${Resource.fileStorages}/url/${fileId}`;
  const options = { method: "GET" };

  const res = await jsonHttpClient(url, options);
  const fileUrl = JSON.parse(res.body).data;
  return fileUrl;
}
