import * as React from "react";
import { Fragment } from "react";
import { useSidebarState } from "react-admin";

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useStyles } from "./SubMenu.styles";

export const SubMenu = (props) => {
  const { title, icon, handleToggle, isMenuOpen, children } = props;
  const [isSidebarOpen] = useSidebarState();
  const classes = useStyles();

  const subMenuHeader = (
    <MenuItem
      component="button"
      onClick={handleToggle}
      className={
        (classes.menuitem, !isSidebarOpen ? classes.menuClosed : undefined)
      }
    >
      <ListItemIcon className={classes.subMenuIcon}>{icon}</ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {title}
      </Typography>
      {isSidebarOpen ? (
        <Badge className={classes.subMenuArrow}>
          {isMenuOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Badge>
      ) : (
        ""
      )}
    </MenuItem>
  );

  return (
    <Fragment>
      {isSidebarOpen ? (
        subMenuHeader
      ) : (
        <Tooltip title={title} placement="right">
          {subMenuHeader}
        </Tooltip>
      )}
      <Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={
            isSidebarOpen
              ? classes.collapseListSpaced
              : classes.collapseListCompact
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};
