import { Grid, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useTranslate,
} from "react-admin";

import { FileInput } from "../../components/common";
import { Resource as ResourceName } from "../../utils/enums/resource.enum";

const FREQUENCIES_CHOICES = [
  "every_day",
  "every_month",
  "every_two_months",
  "every_three_months",
  "every_six_months",
  "twice_a_month",
  "nc",
].map((f) => ({ id: f, name: `resources.providers.frequencies.${f}` }));

const PROVIDER_TYPES = [
  "juicy",
  "chorus",
].map((f) => ({ id: f, name: `resources.providers.types.${f}` }));

const filters = [
  <TextInput source="name" alwaysOn resettable />,
  <ReferenceInput
    source="connectorId"
    reference={ResourceName.connectors}
    alwaysOn={true}
    allowEmpty={false}
    resettable={true}
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const ProviderList = (props) => {
  const useStyles = makeStyles(() => ({
    image: { height: "1.8em", margin: 0 },
  }));
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <List filters={filters} {...props}>
      <Datagrid rowClick="toggleSelection">
        <ImageField source="logo.url" label="" classes={classes} />
        <TextField source="name" />
        <FunctionField
          source="url"
          sortable={false}
          render={(record) => (
            <Link href={record.url} target="_blank">
              {translate("resources.providers.actions.accessWebsite")}
            </Link>
          )}
        />
        <ReferenceField
          source="connectorId"
          reference={ResourceName.connectors}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label={translate("resources.providers.fields.providerType")}
          render={(record) =>
            translate(
              `resources.providers.types.${record.providerType}`
            )
          }
        />
        <FunctionField
          label={translate("resources.providers.fields.retrieveFrequency")}
          render={(record) =>
            translate(
              `resources.providers.frequencies.${record.retrieveFrequency}`
            )
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ProviderEdit = (props) => {
  const translate = useTranslate();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-start"
      alignItems="stretch"
    >
      <Grid item xs={12} md={6}>
        <Edit {...props} mutationMode="pessimistic">
          <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()} />
            <TextInput source="url" validate={required()} />
            <ReferenceInput
              source="connectorId"
              reference={ResourceName.connectors}
              resettable={true}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput
                filterToQuery={(searchText) =>
                  searchText ? { name: searchText } : {}
                }
                debounce={500}
              />
            </ReferenceInput>
            <SelectInput
              source="providerType"
              label={translate("resources.providers.fields.providerType")}
              validate={required()}
              choices={PROVIDER_TYPES}
              defaultValue={PROVIDER_TYPES[0].id}
            />
            <SelectInput
              source="retrieveFrequency"
              label={translate("resources.providers.fields.retrieveFrequency")}
              validate={required()}
              choices={FREQUENCIES_CHOICES}
              defaultValue={FREQUENCIES_CHOICES[0].id}
            />
          </SimpleForm>
        </Edit>
      </Grid>
      <Grid item xs={12} md={6}>
        <Edit {...props} mutationMode="pessimistic">
          <SimpleForm toolbar={null}>
            <FileInput source="logo" type="image" nameField="originalname" />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};

export const ProviderCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="url" validate={required()} />
        <ReferenceInput
          source="connectorId"
          reference={ResourceName.connectors}
          resettable={true}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            filterToQuery={(searchText) =>
              searchText ? { name: searchText } : {}
            }
            debounce={500}
          />
        </ReferenceInput>
        <SelectInput
          source="providerType"
          label={translate("resources.providers.fields.providerType")}
          validate={required()}
          choices={PROVIDER_TYPES}
          defaultValue={PROVIDER_TYPES[0].id}
        />
        <SelectInput
          source="retrieveFrequency"
          label={translate("resources.providers.fields.retrieveFrequency")}
          validate={required()}
          choices={FREQUENCIES_CHOICES}
          defaultValue={FREQUENCIES_CHOICES[0].id}
        />
      </SimpleForm>
    </Create>
  );
};
