import { Fragment, useState } from "react";
import { TextInput, useTranslate } from "react-admin";
import { useWatch } from "react-hook-form";

export const ApiInputs = ({ isEdit }) => {
  const translate = useTranslate();
  const emails = useWatch({ name: "emails" });
  const apiKey = useWatch({ name: "apiKey" });
  const url = useWatch({ name: "url" });
  const [hasApiKeyChanged, setHasApiKeyChanged] = useState(false);

  const urlValidator = (values) => {
    if (!emails?.length && !values) {
      return translate("validation.emailOrApi");
    }
    if (apiKey && !values) {
      return translate("validation.url");
    }
    return undefined;
  };

  const apiKeyValidator = (values) => {
    if (url && !values) {
      return translate("validation.apiKey");
    }
    return undefined;
  };

  return (
    <Fragment>
      <TextInput source="url" validate={urlValidator} />
      <TextInput
        source="apiKey"
        validate={apiKeyValidator}
        onChange={() => setHasApiKeyChanged(true)}
        format={(value) => (hasApiKeyChanged || !isEdit ? value : "*****")}
      />
    </Fragment>
  );
};
