import { Show, useTranslate } from "react-admin";
import { Tab, Tabs } from "@mui/material";
import { Fragment, useState } from "react";

import { SummaryTab } from "./SummaryTab";
import { JobsTab } from "./JobsTab";
import { JournalTab } from "./JournalTab";

export const SynchronisationPanel = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const translate = useTranslate();

  return (
    <Fragment>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={translate("syncTabs.summary.name")} />
        <Tab label={translate("syncTabs.jobs.name")} />
        <Tab label={translate("syncTabs.journal.name")} />
      </Tabs>
      <Show actions={null} {...props} title=" ">
        <Fragment>
          {tabValue === 0 && <SummaryTab />}
          {tabValue === 1 && <JobsTab />}
          {tabValue === 2 && <JournalTab />}
        </Fragment>
      </Show>
    </Fragment>
  );
};
