import * as React from "react";
import PropTypes from "prop-types";
import { Labeled, useRecordContext, useTranslate } from "react-admin";
import { makeStyles } from "@mui/styles";

import { fetchSignedUrl } from "../../../utils/Nrj";

const useStyles = makeStyles({
  fullWidth: { width: "100%" },
  displayField: {
    width: "100%",
    height: "500px",
  },
});

const getLogs = async (fileId, setLogs) => {
  try {
    if (!fileId) {
      setLogs({ info: "No log file to be displayed" });
      return;
    }
    const url = await fetchSignedUrl(fileId);
    const res = await fetch(url);
    const response = await res.json();
    setLogs(response);
  } catch (e) {
    setLogs({ error: e.message || "error_retrieve" });
  }
};

export const LogsSummary = (props) => {
  const { source, label } = props;
  const record = useRecordContext(props);
  const classes = useStyles();
  const translate = useTranslate();
  const [logs, setLogs] = React.useState({
    info: "Logs are loading",
  });

  React.useEffect(() => {
    getLogs(record[source], setLogs);
  }, [record, source]);

  const rows = Object.keys(logs).map((key) => {
    return (
      <tr>
        <th>{translate(`resources.internal-imports.logs.${key}`)}</th>
        <td>{String(logs[key])}</td>
      </tr>
    );
  });

  return (
    <div className={classes.fullWidth}>
      <Labeled label={label} className={classes.fullWidth}>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </Labeled>
    </div>
  );
};

LogsSummary.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
