import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";

import { CustomLayout } from "./layout";
import {
  ConnectorCreate,
  ConnectorEdit,
  ConnectorList,
} from "./pages/BillSyncManager/ConnectorsPage";
import {
  CustomerAreasCreate,
  CustomerAreasEdit,
  CustomerAreasList,
} from "./pages/BillSyncManager/CustomerAreasPage";
import {
  ProviderCreate,
  ProviderEdit,
  ProviderList,
} from "./pages/BillSyncManager/ProvidersPage";
import {
  SynchronizationCreate,
  SynchronizationEdit,
  SynchronizationList,
} from "./pages/BillSyncManager/SynchronizationsPage";
import Dashboard from "./pages/Dashboard";
import {
  ExternalContractorCreate,
  ExternalContractorEdit,
  ExternalContractorList,
} from "./pages/IOT/ExternalContractorPage";
import {
  SourcesCreate,
  SourcesEdit,
  SourcesList,
} from "./pages/IOT/SourcePage";
import {
  InternalImportCreate,
  InternalImportsList,
} from "./pages/Nrj/InternalImportsPage";
import {
  FeatureFlagsList,
  FeatureFlagsCreate,
  FeatureFlagsEdit,
} from "./pages/FeatureFlago/Flags";
import {
  ApplicationsList,
  ApplicationsCreate,
  ApplicationsEdit

} from "./pages/FeatureFlago/Applications";

import LoginPage from "./pages/Security/LoginPage";
import SuccessPage from "./pages/Security/SuccessPage";
import authProvider from "./services/authProvider";
import dataProvider from "./services/dataProvider";
import { i18nProvider } from "./services/i18nProvider";
import { Resource as ResourceName } from "./utils/enums";

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    locale="fr"
    i18nProvider={i18nProvider}
    loginPage={LoginPage}
    layout={CustomLayout}
  >
    <CustomRoutes noLayout>
      <Route path="/success/:jwt" element={<SuccessPage />} />
      <Route path="/login/failure" element={<LoginPage />} />
    </CustomRoutes>
    <Resource
      name={ResourceName.connectors}
      list={ConnectorList}
      create={ConnectorCreate}
      edit={ConnectorEdit}
      recordRepresentation="name"
    />
    <Resource
      name={ResourceName.providers}
      list={ProviderList}
      create={ProviderCreate}
      edit={ProviderEdit}
      recordRepresentation="name"
    />
    <Resource
      name={ResourceName.customerAreas}
      options={{ label: "Customer Areas" }}
      list={CustomerAreasList}
      create={CustomerAreasCreate}
      edit={CustomerAreasEdit}
      recordRepresentation="name"
    />
    <Resource
      name={ResourceName.synchronizations}
      list={SynchronizationList}
      create={SynchronizationCreate}
      edit={SynchronizationEdit}
    />
    <Resource name={ResourceName.synchronizationFiles} />
    <Resource name={ResourceName.companies} recordRepresentation="name" />
    <Resource name={ResourceName.groups} recordRepresentation="name" />
    <Resource
      name={ResourceName.internalImports}
      list={InternalImportsList}
      create={InternalImportCreate}
    />
    <Resource
      name={ResourceName.sources}
      recordRepresentation="name"
      list={SourcesList}
      create={SourcesCreate}
      edit={SourcesEdit}
    />
    <Resource
      name={ResourceName.externalContractors}
      list={ExternalContractorList}
      create={ExternalContractorCreate}
      edit={ExternalContractorEdit}
    />
    <Resource name={ResourceName.customers} recordRepresentation="name" />
    <Resource
      name={ResourceName.flags}
      list={FeatureFlagsList}
      create={FeatureFlagsCreate}
      edit={FeatureFlagsEdit}
      />
     <Resource
      name={ResourceName.applications}
      list={ApplicationsList}
      create={ApplicationsCreate}
      edit={ApplicationsEdit}
    />
  </Admin>
);

export default App;
