import PropTypes from "prop-types";
import { cloneElement, useState } from "react";
import { Button } from "react-admin";

import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  item: {
    height: "36px",
    justifyContent: "normal",
    padding: "16px 6px",
  },
});

export const ActionMenu = (props) => {
  const { children = [], maxHeight = 36 * 7, minWidth = 200, ...rest } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={Boolean(anchorEl) ? "long-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight, minWidth } }}
        MenuListProps={{
          className: classes.list,
        }}
      >
        {children.map((child) =>
          cloneElement(child, { className: classes.item, ...rest })
        )}
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  children: PropTypes.arrayOf(Button),
  maxHeight: PropTypes.number,
  minWidth: PropTypes.number,
};
