import {
  ChipField,
  Datagrid,
  List,
  TextField,
  useTranslate,
  useRecordContext,
} from "react-admin";

import { Resource as ResourceName } from "../../../utils/enums";

export const FilesPanel = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <List
      {...props}
      resource={ResourceName.synchronizationFiles}
      filter={{ jobUuid: record.jobId }}
      sort={{ field: "_id", order: "ASC" }}
    >
      <Datagrid rowClick="#" bulkActionButtons={false}>
        <TextField
          source="uuid"
          label={translate("syncTabs.jobs.files.fields.uuid")}
        />
        <TextField
          source="metadata.fileName"
          label={translate("syncTabs.jobs.files.fields.fileName")}
        />
        <ChipField
          source="state"
          label={translate("syncTabs.jobs.files.fields.state")}
        />
      </Datagrid>
    </List>
  );
};
