import {
  AutocompleteInput,
  ReferenceInput,
  required,
  useTranslate,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Resource } from "../../../utils/enums/";

export const CustomerAreasInput = () => {
  const { setValue } = useFormContext();
  const translate = useTranslate();
  const handleCustomerAreaChange = () => {
    setValue("groupId", undefined);
  };

  return (
    <ReferenceInput
      source="customerAreaId"
      label={translate("resources.synchronizations.fields.customerAreaId")}
      reference={Resource.customerAreas}
      validate={required()}
      resettable="true"
      sort={{ field: "name", order: "ASC" }}
      onChange={handleCustomerAreaChange}
    >
      <AutocompleteInput
        filterToQuery={(searchText) => (searchText ? { name: searchText } : {})}
        debounce={500}
      />
    </ReferenceInput>
  );
};
