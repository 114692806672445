import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  email,
  useTranslate,
} from "react-admin";
import { useWatch } from "react-hook-form";

import { noDuplicateInArrayValidator } from "../../../utils/validators";

export const EmailsInput = () => {
  const translate = useTranslate();
  const url = useWatch({ name: "url" });

  const emailsValidator = (values) => {
    if (!values?.length && !url) {
      return translate("validation.emailOrApi");
    }
    return undefined;
  };

  return (
    <ArrayInput
      source="emails"
      validate={[
        noDuplicateInArrayValidator({
          message: translate("validation.noDuplicate"),
        }),
        emailsValidator,
      ]}
    >
      <SimpleFormIterator>
        <TextInput validate={email(translate("validation.email"))} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
